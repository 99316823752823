<template>
  <div>
    <NavBar :title="pageTitle" @showNavigation="nav = !nav" />
    <v-main>
      <v-container fluid>
        <h1>HERERERE</h1>
        <h1>HERERERE</h1>
        <h1>HERERERE</h1>
        <h1>HERERERE</h1>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import NavBar from '@/components/UI/nav-bar'

export default {
  name: 'FSHome',
  components: { NavBar },
  mounted() {
    this.$emit('loaded')
  },
  setup() {
    const pageTitle = 'Flag Administrators Home'
    return {
      pageTitle
    }
  }
}
</script>
